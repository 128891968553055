<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-dark">{{
          $t('DROPDOWN_NOTIFICATION.UNFISCALIZED_BILLS')
        }}</span>
        <span
          class="btn btn-text btn-danger btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{ unFiscalizedBillsNumber }}
        </span>
      </h4>

      <div class="d-flex flex-center flex-column">
        <router-link
          to="/bills/unfiscalized"
          class="btn btn-sm btn-primary px-10"
          >{{ $t('MAIN_MENU.VIEW') }}</router-link
        >
      </div>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active text-dark"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            {{ $t('DROPDOWN_NOTIFICATION.UNFISCALIZED') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link text-dark"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            {{ $t('DROPDOWN_NOTIFICATION.LAST_TEN') }}
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative"
        >
          <template v-for="(lastBill, i) in unFiscalizedBills">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-text">
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3">JIR: </span>
                    <span>{{ lastBill.jir }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3">ZKI: </span>
                    <span>{{ lastBill.zki }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3"
                      >{{ $t('GENERAL.AMOUNT') }}:
                    </span>
                    <span>{{ lastBill.amount }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3"
                      >{{ $t('GENERAL.TIME') }}:
                    </span>
                    <span>{{ lastBill.createdAt | formatDateTime }}</span>
                  </div>
                </div>
              </div>
            </a>
          </template>
          <template v-if="unFiscalizedBills.length < 1">
            <p class="m-0">{{ $t('DROPDOWN_NOTIFICATION.ALL_FISCALIZED') }}</p>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="navi navi-hover scroll my-4"
          style="max-height: 40vh; position: relative"
        >
          <template v-for="(lastBill, i) in last10Bills">
            <a href="#" class="navi-item" v-bind:key="i">
              <div class="navi-link">
                <div class="navi-text">
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3">JIR: </span>
                    <span>{{ lastBill.jir }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3">ZKI: </span>
                    <span>{{ lastBill.zki }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3"
                      >{{ $t('GENERAL.AMOUNT') }}:
                    </span>
                    <span>{{ lastBill.amount }}</span>
                  </div>
                  <div class="text-muted">
                    <span class="text-dark font-weight-bold mr-3"
                      >{{ $t('GENERAL.TIME') }}:
                    </span>
                    <span>{{ lastBill.createdAt | formatDateTime }}</span>
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <div class="d-flex flex-center text-center text-muted min-h-200px">
          {{ $t('DROPDOWN_NOTIFICATION.CAUGHT_UP') }}<br />{{
            $t('DROPDOWN_NOTIFICATION.NO_NOTIFICATIONS')
          }}
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'KTDropdownNotification',
  data() {
    return {
      tabIndex: 0,
      last10Bills: [],
      unFiscalizedBills: [],
      unFiscalizedBillsNumber: 0,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
    backgroundImage() {
      return process.env.BASE_URL + 'media/misc/bg-1.jpg'
    },
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&isFiscal=false&order[createdAt]=desc&pagination=false`
      )
      .then(({ data }) => {
        this.unFiscalizedBills = [...data['hydra:member']]
        this.unFiscalizedBillsNumber = data['hydra:totalItems']

        axios
          .get(
            `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&order[createdAt]=desc&pagination=false`
          )
          .then(({ data }) => {
            this.last10Bills = [...data['hydra:member']]
          })
      })
  },
  methods: {
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.nav-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseFloat(event.target.getAttribute('data-tab'))

      // set current active tab
      event.target.classList.add('active')
    },
  },
}
</script>
