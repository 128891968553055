<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        {{ $t('GENERAL.HI') }},
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ currentUserPersonalInfo.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUserPersonalInfo.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          {{ $t('GENERAL.USER_PROFILE') }}
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              class="symbol-label"
              :src="currentUserPersonalInfo.photo"
              alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/custom-pages/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ getFullName }}
            </router-link>
            <div class="text-muted mt-1">OIB: {{ currentUserCompanyOib }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUserPersonalInfo.email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              {{ $t('GENERAL.SIGN_OUT') }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/profile/overview"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/profile/overview">
                  <div class="font-weight-bold">
                    {{ $t('GENERAL.USER_PROFILE') }}
                  </div>
                </router-link>
                <div class="text-muted">
                  {{ $t('GENERAL.USER_PROFILE_DETAILS') }}
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/loyalty/cards/upload"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
            v-if="currentUserAccountInfo.role === 'ROLE_SUPER_ADMIN'"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/loyalty/cards/upload">
                  <div class="font-weight-bold">
                    <!-- {{ $t('GENERAL.USER_PROFILE') }} -->
                    Loyalty Card Upload
                  </div>
                </router-link>
                <div class="text-muted">
                  <!-- {{ $t('GENERAL.USER_PROFILE_DETAILS') }} -->
                  Upload a CSV or XLSX file
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/loyalty/promo-boxes/list"
            @click.native="closeOffcanvas"
            class="navi-item"
            v-if="currentUserAccountInfo.role === 'ROLE_SUPER_ADMIN'"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">Promo Boxes Update</div>
                <div class="text-muted">Update Loyalty promo boxes</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <div
          class="top-gun"
          v-if="
            this.currentUserCompanyOib === '05959244936' &&
            (this.currentUserPersonalInfo.email.includes(
              'davor.botincan@kidsdelux.hr'
            ) ||
              this.currentUserPersonalInfo.email.includes('@arsfutura.co'))
          "
        >
          <VtSwitch
            @checkedEvent="onTopGunChange"
            :is-checked="currentIsTopGun"
            classes="switch switch-icon mr-5"
            name="companySendEmailNotifications"
          />
          <span class="font-weight-bold" for="companyinVatSystem">
            Top gun
          </span>
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
.top-gun {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { LOGOUT } from '@/core/services/store/auth.module'
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js'
import KTOffcanvas from '@/assets/js/components/offcanvas.js'

import axios from 'axios'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import { UPDATE_SOME_ACCOUNT_INFO } from '../../../../core/services/store/profile.module'

export default {
  components: { VtSwitch },
  name: 'KTQuickUser',
  data() {
    return {
      company: { isTopGun: false },
    }
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs['kt_quick_user'])
    axios
      .get(`${process.env.VUE_APP_ROUTE_API_URL}${this.currentUserCompany}`)
      .then(({ data }) => {
        this.company = data
      })
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: 'login' }))
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide()
    },
    onTopGunChange() {
      this.company.isTopGun = !this.company.isTopGun
      this.company.pdv = parseFloat(this.company.pdv)

      axios.put(
        `${process.env.VUE_APP_ROUTE_API_URL}${this.currentUserCompany}`,
        this.company
      )

      this.$store.dispatch(UPDATE_SOME_ACCOUNT_INFO, {
        isTopGun: this.company.isTopGun,
      })
    },
  },
  computed: {
    ...mapGetters([
      'currentUserPersonalInfo',
      'currentUserCompanyOib',
      'currentUserAccountInfo',
      'currentUserRole',
      'currentUserCompany',
      'currentIsTopGun',
    ]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        ' ' +
        this.currentUserPersonalInfo.surname
      )
    },
  },
}
</script>
