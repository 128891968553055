<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <span
      class="symbol-label font-weight-bold small text-muted"
      style="position: relative;top: 16px;right: 11px;"
    >
      {{ currentUserAccountInfo.role }}
    </span>

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary"
        >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
            <span class="label label-rounded label-primary">{{
              unFiscalizedBillsNumber
            }}</span>
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Language bar -->
    <div class="topbar-item">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </template>
        <b-dropdown-text tag="div" class="min-w-md-175px">
          <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
        </b-dropdown-text>
      </b-dropdown>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>

    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from '@/view/layout/extras/dropdown/DropdownNotification.vue'
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue'
import KTQuickUser from '@/view/layout/extras/offcanvas/QuickUser.vue'
import i18nService from '@/core/services/i18n.service.js'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'KTTopbar',
  data() {
    return {
      languageFlag: '',
      languages: i18nService.languages,
      unFiscalizedBillsNumber: 0
    }
  },
  components: {
    KTDropdownNotification,
    KTDropdownLanguage,
    KTQuickUser
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_BILLS_API_URL}/api/v1/bills?oib=${this.currentUserCompanyOib}&isFiscal=false&order[createdAt]=desc&pagination=false`
      )
      .then(({ data }) => {
        this.unFiscalizedBillsNumber = data['hydra:totalItems']
      })
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage()
      }).flag
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
    ...mapGetters(['currentUserPersonalInfo', 'currentUserAccountInfo']),
    getLanguageFlag() {
      return this.onLanguageChanged()
    }
  }
}
</script>
