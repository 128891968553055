<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <KTHeaderMobile></KTHeaderMobile>

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <div class="d-flex flex-row flex-column-fluid page">
      <KTAside v-if="asideEnabled"></KTAside>

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <KTHeader></KTHeader>

        <div
            id="kt_content"
            class="content d-flex flex-column flex-column-fluid"
        >
          <KTSubheader
              v-if="subheaderDisplay"
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
          />

          <div class="d-flex flex-column-fluid">
            <div
                class="pt-6"
                :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up">
                <router-view/>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
    <notifications group="user" position="top right"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import KTAside from '@/view/layout/aside/Aside.vue'
import KTHeader from '@/view/layout/header/Header.vue'
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue'
import KTFooter from '@/view/layout/footer/Footer.vue'
import HtmlClass from '@/core/services/htmlclass.service'
import KTSubheader from '@/view/layout/subheader/Subheader.vue'
import KTScrollTop from '@/view/layout/extras/ScrollTop'
import Loader from '@/view/content/Loader.vue'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@/core/services/store/htmlclass.module.js'

export default {
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loader,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')

    // initialize html element classes
    HtmlClass.init(this.layoutConfig())
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({name: 'login'})
    } else {
      // force logout and refresh to get new data from server
      if (this.currentActiveModuleComponents !== undefined &&
          this.currentActiveModuleComponents.dashboard === undefined) {
        console.log('LOGOUT AND REFRESH')
        this.$store.dispatch('logout').then(() => {
          this.$router.push('/')
          location.reload()
        })
      }
    }

    // ! set gates permissions
    if (this.currentUserRole) {
      if (this.currentUserRole === 'ROLE_ADMIN') {
        this.$gates.setRoles("admin")
      } else {
        var role = this.currentUserRole.replace("ROLE_", "").toLowerCase()
        this.$gates.setRoles(role)
      }
      console.log("GATE role:", this.$gates.getRoles(), "| super?", this.$gates.isSuperUser())

    }


    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
    }, 200)
  },
  methods: {},
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'breadcrumbs',
      'pageTitle',
      'layoutConfig',
      'currentActiveModuleComponents',
      'currentUserRole',
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'))
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid'
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo')
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display')
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display')
    },
  },
}
</script>
