<template>
  <!-- begin:: Aside -->
  <div
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
    id="kt_aside"
    ref="kt_aside"
    @mouseover="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <!-- begin:: Aside -->
    <KTBrand></KTBrand>
    <!-- end:: Aside -->

    <!-- begin:: Aside Menu -->
    <div
      class="aside-menu-wrapper flex-column-fluid"
      id="kt_aside_menu_wrapper"
    >
      <div
        ref="kt_aside_menu"
        id="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
        v-bind:class="asideMenuClass"
      >

        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 90vh; position: relative"
        >
          <Menu></Menu>
<!--          <RootUserMenu v-if="currentUserRole === 'ROLE_ROOT'"></RootUserMenu>-->
<!--          <SuperAdminUserMenu-->
<!--            v-if="currentUserRole === 'ROLE_SUPER_ADMIN'"-->
<!--          ></SuperAdminUserMenu>-->
<!--          <AdminUserMenu-->
<!--            v-if="currentUserRole === 'ROLE_ADMIN'"-->
<!--          ></AdminUserMenu>-->
<!--          <WorkerUserMenu-->
<!--            v-if="currentUserRole === 'ROLE_WORKER'"-->
<!--          ></WorkerUserMenu>-->
<!--          <JuniorUserMenu-->
<!--            v-if="currentUserRole === 'ROLE_JUNIOR'"-->
<!--          ></JuniorUserMenu>-->
<!--          <WatcherUserMenu-->
<!--            v-if="currentUserRole === 'ROLE_WATCHER'"-->
<!--          ></WatcherUserMenu>-->
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<script>
import { mapGetters } from 'vuex'
import KTBrand from '@/view/layout/brand/Brand.vue'

import KTLayoutAside from '@/assets/js/layout/base/aside.js'
import KTLayoutAsideMenu from '@/assets/js/layout/base/aside-menu.js'
// import RootUserMenu from '@/view/layout/aside/RootUserMenu'
// import SuperAdminUserMenu from '@/view/layout/aside/SuperAdminUserMenu'
// import AdminUserMenu from '@/view/layout/aside/AdminUserMenu'
// import WorkerUserMenu from '@/view/layout/aside/WorkerUserMenu'
// import JuniorUserMenu from '@/view/layout/aside/JuniorUserMenu'
// import WatcherUserMenu from '@/view/layout/aside/WatcherUserMenu'
import Menu from '@/view/layout/aside/Menu'

export default {
  name: 'KTAside',
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
    }
  },
  components: {
    KTBrand,
    Menu
    // RootUserMenu,
    // SuperAdminUserMenu,
    // AdminUserMenu,
    // WorkerUserMenu,
    // JuniorUserMenu,
    // WatcherUserMenu,
  },
  mounted() {
    this.$nextTick(() => {
      const asideRef = this.$refs['kt_aside']

      // Init Aside
      KTLayoutAside.init(asideRef)

      asideRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
        item.addEventListener('click', () => {
          KTLayoutAside.getOffcanvas().hide()
        })
      })

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs['kt_aside_menu'])
    })
  },
  methods: {
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseEnter() {
      if (this.layoutConfig('aside.self.minimize.hoverable')) {
        // check if the left aside menu is fixed
        if (document.body.classList.contains('aside-fixed')) {
          if (this.outsideTm) {
            clearTimeout(this.outsideTm)
            this.outsideTm = null
          }

          // if the left aside menu is minimized
          if (document.body.classList.contains('aside-minimize')) {
            document.body.classList.add('aside-minimize-hover')

            // show the left aside menu
            document.body.classList.remove('aside-minimize')
          }
        }
      }
    },

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     */
    mouseLeave() {
      if (this.layoutConfig('aside.self.minimize.hoverable')) {
        if (document.body.classList.contains('aside-fixed')) {
          if (this.insideTm) {
            clearTimeout(this.insideTm)
            this.insideTm = null
          }

          // if (document.querySelector('.aside-menu .scroll')) {
          //   document.querySelector('.aside-menu .scroll').scrollTop = 0
          // }

          // if the left aside menu is expand
          if (document.body.classList.contains('aside-minimize-hover')) {
            // hide back the left aside menu
            document.body.classList.remove('aside-minimize-hover')
            document.body.classList.add('aside-minimize')
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses', 'currentUserRole']),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses('aside_menu')
      if (typeof classes !== 'undefined') {
        return classes.join(' ')
      }
      return null
    },
  },
}
</script>
